export enum SystemeEmailStatusEnum {
  active = 'active',
  pending = 'pending',
  blocked = 'blocked',
  approved = 'approved',
}

export const systemeEmailStatusTitles: Record<SystemeEmailStatusEnum, string> = {
  [SystemeEmailStatusEnum.active]: 'dashboard.email_statistics.status.active',
  [SystemeEmailStatusEnum.pending]: 'dashboard.email_statistics.status.pending',
  [SystemeEmailStatusEnum.approved]: 'dashboard.email_statistics.status.approved',
  [SystemeEmailStatusEnum.blocked]: 'dashboard.email_statistics.status.blocked',
}
