export enum EmailStatisticsChartAmountEnum {
  sentAmount = 'sentAmount',
}

export enum EmailStatisticsChartPercentEnum {
  openedPercent = 'openedPercent',
  spamReportsPercent = 'spamReportsPercent',
  bouncedPercent = 'bouncedPercent',
}

export const emailStatisticsChartTitles: Record<
  EmailStatisticsChartAmountEnum | EmailStatisticsChartPercentEnum,
  string
> = {
  [EmailStatisticsChartAmountEnum.sentAmount]: 'dashboard.email_statistics.emails_sent_number',
  [EmailStatisticsChartPercentEnum.spamReportsPercent]:
    'dashboard.email_statistics.spam_percent_average',
  [EmailStatisticsChartPercentEnum.openedPercent]:
    'dashboard.email_statistics.opened_percent_average',
  [EmailStatisticsChartPercentEnum.bouncedPercent]:
    'dashboard.email_statistics.bounced_percent_average',
}
