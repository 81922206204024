import EmailStatisticsCharts from 'modules/email-statistics/components/email-statistics-charts'
import EmailStatisticsFilter from 'modules/email-statistics/components/email-statistics-filter'
import EmailStatisticsPool from 'modules/email-statistics/components/email-statistics-pool'
import EmailStatisticsTable from 'modules/email-statistics/components/email-statistics-table'
import { EmailStatisticsLoco } from 'modules/email-statistics/constants/email-statistics-loco'
import { EmailStatisticsFilterInterface } from 'modules/email-statistics/types/email-statistics-interface'
import { NextPage } from 'next'
import React from 'react'
import { SectionMessage } from 'shared/components/section-message'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { useFilterWithDate } from 'shared/route-query/hooks/use-filter/use-filter-with-date'
import { getBaseServerSideProps } from 'shared/utils/get-base-server-side-props'
import { getMonthDateRange } from 'shared/utils/get-dates'

const defaultFilter: EmailStatisticsFilterInterface = {
  dateSince: null,
  dateTill: null,
}

const EmailStatistics: NextPage = () => {
  const { t } = useLocoTranslation()

  const { filter, setFilter } = useFilterWithDate(defaultFilter, getMonthDateRange)

  return (
    <div className={'flex flex-col gap-5'}>
      <h1>{t('dashboard.email_statistics.email_statistics')}</h1>
      <SectionMessage>{t('dashboard.email.new_google_policy_warning_message')}</SectionMessage>
      <div className={'flex gap-3 justify-between flex-col sm:flex-row'}>
        <EmailStatisticsPool />
        <EmailStatisticsFilter filter={filter} setFilter={setFilter} />
      </div>
      <EmailStatisticsCharts filter={filter} />
      <EmailStatisticsTable filter={filter} />
    </div>
  )
}

export const getStaticProps = getBaseServerSideProps(
  EmailStatisticsLoco,
  'header.top_menu.email_statistics',
)

export default EmailStatistics
