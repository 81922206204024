import {
  EmailDeliveryStatusEnum,
  emailStatusColor,
} from 'modules/email-statistics/enums/email-delivery-status-enum'
import { EmailTypeEnum } from 'modules/email-statistics/enums/email-type-enum'

const OPENED_PERCENT_ALERT_THRESHOLD = 3
const OPENED_PERCENT_WARNING_THRESHOLD = 9

const SPAM_REPORT_MIN_AMOUNT_TO_CHECK = 6
const SPAM_REPORT_PERCENT_WARNING_THRESHOLD = 0.09
const SPAM_REPORT_PERCENT_ALERT_THRESHOLD = 0.2

const BOUNCED_EMAIL_MIN_AMOUNT_TO_CHECK = 10
const BOUNCED_PERCENT_WARNING_THRESHOLD = 2
const BOUNCED_PERCENT_ALERT_THRESHOLD = 5
const BOUNCED_PERCENT_ALERT_THRESHOLD_DOUBLE_OPT_IN_CONFIRMATION_EMAIL = 30

export const getSpamReportStatus = (totalAmount: number, spamReportAmount: number) => {
  if (totalAmount == 0 || spamReportAmount <= SPAM_REPORT_MIN_AMOUNT_TO_CHECK) {
    return EmailDeliveryStatusEnum.normal
  }

  const spamReportPercent = (spamReportAmount / totalAmount) * 100

  if (spamReportPercent >= SPAM_REPORT_PERCENT_ALERT_THRESHOLD) {
    return EmailDeliveryStatusEnum.alert
  }

  if (spamReportPercent >= SPAM_REPORT_PERCENT_WARNING_THRESHOLD) {
    return EmailDeliveryStatusEnum.warning
  }

  return EmailDeliveryStatusEnum.normal
}

export const getSpamReportColor = (totalAmount: number, spamReportAmount: number) =>
  emailStatusColor[getSpamReportStatus(totalAmount, spamReportAmount)]

export const getOpenedStatus = (totalAmount: number, openedAmount: number) => {
  if (totalAmount == 0 || totalAmount <= 30) {
    return EmailDeliveryStatusEnum.normal
  }

  const percent = (openedAmount / totalAmount) * 100

  if (percent < OPENED_PERCENT_ALERT_THRESHOLD) {
    return EmailDeliveryStatusEnum.alert
  }

  if (percent <= OPENED_PERCENT_WARNING_THRESHOLD) {
    return EmailDeliveryStatusEnum.warning
  }

  return EmailDeliveryStatusEnum.normal
}

export const getOpenedColor = (totalAmount: number, openedAmount: number) =>
  emailStatusColor[getOpenedStatus(totalAmount, openedAmount)]

export const getBouncedStatus = (
  totalAmount: number,
  bouncedAmount: number,
  emailType: EmailTypeEnum,
) => {
  if (totalAmount == 0 || bouncedAmount <= BOUNCED_EMAIL_MIN_AMOUNT_TO_CHECK) {
    return EmailDeliveryStatusEnum.normal
  }

  const bouncedPercent = (bouncedAmount / totalAmount) * 100

  const alertThreshold =
    emailType === EmailTypeEnum.double_opt_in_confirmation
      ? BOUNCED_PERCENT_ALERT_THRESHOLD
      : BOUNCED_PERCENT_ALERT_THRESHOLD_DOUBLE_OPT_IN_CONFIRMATION_EMAIL
  if (bouncedPercent >= alertThreshold) {
    return EmailDeliveryStatusEnum.alert
  }

  if (bouncedPercent >= BOUNCED_PERCENT_WARNING_THRESHOLD) {
    return EmailDeliveryStatusEnum.warning
  }

  return EmailDeliveryStatusEnum.normal
}

export const getBouncedColor = (
  totalAmount: number,
  bouncedAmount: number,
  emailType: EmailTypeEnum,
) => emailStatusColor[getBouncedStatus(totalAmount, bouncedAmount, emailType)]
