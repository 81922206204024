export enum EmailDeliveryStatusEnum {
  normal = 'normal',
  warning = 'warning',
  alert = 'alert',
  quarantine = 'quarantine',
}

export const emailStatusColor: Record<EmailDeliveryStatusEnum, string> = {
  [EmailDeliveryStatusEnum.normal]: 'text-gray-300',
  [EmailDeliveryStatusEnum.warning]: 'text-orange',
  [EmailDeliveryStatusEnum.alert]: 'text-danger',
  [EmailDeliveryStatusEnum.quarantine]: 'text-danger',
}
