import { EMAIL_STATISTICS_API } from 'modules/email-statistics/api/email-statistics-api'
import { EmailStatisticsPoolInterface } from 'modules/email-statistics/types/email-statistics-interface'
import useUser from 'shared/hooks/use-user'
import { baseFetcher } from 'shared/utils/http-client'
import useSWR from 'swr'

export const useEmailStatisticsPool = () => {
  const { user } = useUser()

  return useSWR<EmailStatisticsPoolInterface>(() => {
    if (!user) return
    return `${EMAIL_STATISTICS_API}/pool-data`
  }, baseFetcher)
}
