import { EMAIL_STATISTICS_API } from 'modules/email-statistics/api/email-statistics-api'
import {
  EmailStatisticsFilterInterface,
  EmailStatisticsTableApiInterface,
} from 'modules/email-statistics/types/email-statistics-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { SwrPaginationInterface } from 'shared/types/pagination-interface'
import useSWR from 'swr'

interface UseEmailStatisticTableInterface extends SwrPaginationInterface {
  filter: EmailStatisticsFilterInterface
}

export const useEmailStatisticsTable = ({
  limit,
  pagination,
  filter,
}: UseEmailStatisticTableInterface) => {
  const { user } = useUser()

  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    EmailStatisticsTableApiInterface
  >({ method: RequestMethodsEnum.get })

  const filterApi = `${filter.dateSince !== null ? `&filter[dateSince]=${filter.dateSince}` : ''}${
    filter.dateTill !== null ? `&filter[dateTill]=${filter.dateTill}` : ''
  }`

  return useSWR(() => {
    if (!user || filter.dateTill === null) return
    if (pagination.startFromId === undefined && limit) {
      return `${EMAIL_STATISTICS_API}/list?pagination[limit]=${limit}${filterApi}`
    } else if (pagination.startFromId && limit) {
      return `${EMAIL_STATISTICS_API}/list?pagination[startFrom]=${pagination.startFromId}&pagination[order]=${pagination.order}&pagination[limit]=${limit}&${filterApi}`
    }
    return null
  }, fetcher)
}
