import { EmailTypeEnum, emailTypeTitle } from 'modules/email-statistics/enums/email-type-enum'
import {
  SystemeEmailStatusEnum,
  systemeEmailStatusTitles,
} from 'modules/email-statistics/enums/systeme-email-status-enum'
import { useEmailStatisticsTable } from 'modules/email-statistics/hooks/use-email-statistics-table'
import {
  EmailStatisticsFilterInterface,
  EmailStatisticsTableInterface,
} from 'modules/email-statistics/types/email-statistics-interface'
import {
  getBouncedColor,
  getOpenedColor,
  getSpamReportColor,
} from 'modules/email-statistics/utils/get-email-delivery-status'
import React from 'react'
import { Hint } from 'shared/components/hint'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import ListingLayout from 'shared/components/listing-layout'
import { Table } from 'shared/components/table/table'
import { ColumnDefinitionInterface } from 'shared/components/table/types/column-interface'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { usePagination } from 'shared/route-query/hooks/use-pagination'
import { dateFormat } from 'shared/utils/date-time-format'
import { getPercent } from 'shared/utils/get-percent'

interface EmailDashboardTableProps {
  filter: EmailStatisticsFilterInterface
}

const isNewsletter = (emailType: EmailTypeEnum) =>
  emailType === EmailTypeEnum.newsletter || emailType === EmailTypeEnum.newsletter_split_test

const EmailStatisticsTable = ({ filter }: EmailDashboardTableProps) => {
  const { t } = useLocoTranslation()

  const { user } = useUser()

  const { pagination, setPagination, limit, setLimit } = usePagination(true)

  const { data: emailDashboardTable } = useEmailStatisticsTable({ limit, pagination, filter })

  const columns: ColumnDefinitionInterface<EmailStatisticsTableInterface>[] = [
    {
      key: 'mailing',
      header: t('global.email_address'),
      widthPercent: 40,
      onRender: email => (
        <div className={'flex gap-3 items-center w-full truncate'}>
          <div className={'flex flex-col w-full truncate'}>
            <LinkWithoutPrefetch
              className={'primary-link truncate max-w-fit'}
              href={`/mailing/${email.id}/statistics`}
            >
              {email.subject}
            </LinkWithoutPrefetch>
            <div className={'flex gap-1.5 w-fill'}>
              <span className={'text-xs truncate'}>{t(emailTypeTitle[email.emailType])}</span>
              {isNewsletter(email.emailType) && email.scheduledAt ? (
                <span className={'text-xs truncate'}>
                  {dateFormat(email.scheduledAt, {
                    dateFormat: user?.dateFormat,
                    timeZone: user?.timezone,
                  })}
                </span>
              ) : email.isNewsletterRemoved ? (
                <span className={'text-xs truncate'}>
                  {t('dashboard.email_statistics.table.over_month_ago').toLowerCase()}
                </span>
              ) : null}
            </div>
          </div>
          {(email.status === SystemeEmailStatusEnum.blocked ||
            email.status === SystemeEmailStatusEnum.pending) && (
            <span className={'text-danger'}>{t(systemeEmailStatusTitles[email.status])}</span>
          )}
        </div>
      ),
    },
    {
      key: 'stats',
      header: t('dashboard.email_statistics.amount_sent'),
      widthPercent: 15,
      onRender: email => <span>{email.stats.sentAmount}</span>,
    },
    {
      key: 'stats',
      header: t('dashboard.email_statistics.amount_opened'),
      widthPercent: 15,
      onRender: email => (
        <span
          className={getOpenedColor(email.stats.sentAmount, email.stats.openedAmount)}
        >{`${getPercent(email.stats.sentAmount, email.stats.openedAmount)}% (${
          email.stats.openedAmount
        })`}</span>
      ),
    },
    {
      key: 'stats',
      header: (
        <Hint label={t('dashboard.email_statistics.spam_percent_description')}>
          {t('dashboard.email_statistics.spam_reports')}
        </Hint>
      ),
      widthPercent: 15,
      onRender: email => (
        <span
          className={getSpamReportColor(
            email.stats.openedAmountWithEnabledSpamReportFeedback,
            email.stats.spamReportAmount,
          )}
        >{`${getPercent(
          email.stats.openedAmountWithEnabledSpamReportFeedback,
          email.stats.spamReportAmount,
        )}%`}</span>
      ),
    },
    {
      key: 'stats',
      header: t('dashboard.email_statistics.bounced'),
      widthPercent: 15,
      onRender: email => (
        <span
          className={getBouncedColor(
            email.stats.sentAmount,
            email.stats.bouncedAmount,
            email.emailType,
          )}
        >{`${getPercent(email.stats.sentAmount, email.stats.bouncedAmount)}% (${
          email.stats.bouncedAmount
        })`}</span>
      ),
    },
  ]

  return (
    <div>
      <ListingLayout
        data={emailDashboardTable}
        title={t('dashboard.email_statistics.emails')}
        counter={false}
        type={'compact'}
      >
        <Table
          data={emailDashboardTable}
          columns={columns}
          paginator={{ setPagination, pagination }}
          limiter={{ limit, setLimit }}
        />
      </ListingLayout>
    </div>
  )
}

export default EmailStatisticsTable
