export enum EmailTypeEnum {
  newsletter = 'newsletter',
  newsletter_split_test = 'newsletter_split_test',
  campaign = 'campaign',
  automation = 'automation',
  automation_to_specific_address = 'automation_to_specific_address',
  lecture = 'lecture',
  webinar_notification = 'webinar_notification',
  double_opt_in_confirmation = 'double_opt_in_confirmation',
}

export const emailTypeTitle: Record<EmailTypeEnum, string> = {
  [EmailTypeEnum.newsletter]: 'dashboard.email_statistics.type.newsletter',
  [EmailTypeEnum.campaign]: 'dashboard.email_statistics.type.campaign',
  [EmailTypeEnum.automation]: 'dashboard.email_statistics.type.automation',
  [EmailTypeEnum.automation_to_specific_address]:
    'dashboard.email_statistics.type.automation_specific',
  [EmailTypeEnum.lecture]: 'dashboard.email_statistics.type.lecture',
  [EmailTypeEnum.webinar_notification]: 'dashboard.email_statistics.type.webinar_notification',
  [EmailTypeEnum.double_opt_in_confirmation]:
    'dashboard.email_statistics.type.double_opt_in_confirmation',
  [EmailTypeEnum.newsletter_split_test]:
    'dashboard.newsletter.split_test.newsletter_split_test_title',
}
