import { ChartOptions } from 'chart.js'

export const getEmailStatisticsChartOptions = (showGrid: boolean): ChartOptions<'bar'> => ({
  maintainAspectRatio: false,
  responsive: true,
  font: { family: 'Averta' },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      display: showGrid,
      border: { dash: [12, 12], display: false },
      grid: {
        color: function (context) {
          if (context.tick.value === 0) {
            return 'rgba(0, 0, 0, 0)'
          }
          return 'rgb(155 169 179 / 0.3)'
        },
        drawTicks: false,
      },
      ticks: {
        color: '#142D63',
      },
    },
    x: { grid: { display: false }, ticks: { color: '#142D63' } },
  },
})
