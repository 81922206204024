import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Legend,
  LinearScale,
  ScatterDataPoint,
  Title,
  Tooltip,
} from 'chart.js'
import {
  EmailStatisticsChartAmountEnum,
  EmailStatisticsChartPercentEnum,
  emailStatisticsChartTitles,
} from 'modules/email-statistics/enums/email-statistics-chart-enum'
import { useEmailStatisticsCharts } from 'modules/email-statistics/hooks/use-email-statistics-charts'
import {
  EmailStatisticsChartDataType,
  EmailStatisticsFilterInterface,
} from 'modules/email-statistics/types/email-statistics-interface'
import { getEmailStatisticsChartOptions } from 'modules/email-statistics/utils/get-email-statistics-chart-options'
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import Popover from 'shared/components/popover'
import { Tooltip as SioTooltip } from 'shared/components/tooltip'
import { TLocoType, useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import { toShortDate } from 'shared/utils/date-time-format'

interface EmailStatisticsChartsProps {
  filter: EmailStatisticsFilterInterface
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const EmailStatisticsCharts = ({ filter }: EmailStatisticsChartsProps) => {
  const { data: chartsData } = useEmailStatisticsCharts(filter)
  return (
    <div className={'flex flex-wrap gap-5'}>
      <EmailStatisticsChartItem
        isPreFetching={!chartsData}
        type={EmailStatisticsChartAmountEnum.sentAmount}
        total={chartsData?.totalSent}
        showGrid={chartsData?.totalSent !== 0}
        chartStats={chartsData?.byDayStats}
      />
      <EmailStatisticsChartItem
        isPreFetching={!chartsData}
        type={EmailStatisticsChartPercentEnum.openedPercent}
        total={chartsData?.averageStats.openedPercent}
        showGrid={chartsData?.averageStats.openedPercent !== 0}
        chartStats={chartsData?.byDayStats}
      />
      <EmailStatisticsChartItem
        isPreFetching={!chartsData}
        type={EmailStatisticsChartPercentEnum.spamReportsPercent}
        total={chartsData?.averageStats.spamReportsPercent}
        showGrid={chartsData?.averageStats.spamReportsPercent !== 0}
        chartStats={chartsData?.byDayStats}
      />
      <EmailStatisticsChartItem
        isPreFetching={!chartsData}
        type={EmailStatisticsChartPercentEnum.bouncedPercent}
        total={chartsData?.averageStats.bouncedPercent}
        showGrid={chartsData?.averageStats.bouncedPercent !== 0}
        chartStats={chartsData?.byDayStats}
      />
    </div>
  )
}

interface EmailStatisticsChartItemProps {
  isPreFetching: boolean
  type: EmailStatisticsChartAmountEnum | EmailStatisticsChartPercentEnum
  total?: number
  showGrid: boolean
  chartStats?: EmailStatisticsChartDataType
}

const EmailStatisticsChartItem = ({
  type,
  showGrid,
  total,
  isPreFetching,
  chartStats,
}: EmailStatisticsChartItemProps) => {
  const { t } = useLocoTranslation()
  const { intlLocale } = useUser()
  const [data, setData] = useState<ChartData<'bar', (number | ScatterDataPoint | null)[], string>>({
    labels: [],
    datasets: [],
  })

  useEffect(() => {
    if (chartStats) {
      setData({
        labels: Object.keys(chartStats).map(el => toShortDate(el, intlLocale)),
        datasets: [
          {
            data: (Object.keys(chartStats) as Array<keyof EmailStatisticsChartDataType>).map(el =>
              type === EmailStatisticsChartAmountEnum.sentAmount
                ? chartStats[el][type]
                : chartStats[el][type],
            ),
            backgroundColor: '#00A0FF',
            borderRadius: 2,
          },
        ],
      })
    }
  }, [chartStats, type, intlLocale])

  return (
    <div className={'lg:w-[calc(50%-10px)] flex gap-5 flex-col lg:flex-row w-full'}>
      <div
        className={
          'flex flex-col rounded-lg bg-white w-full h-fit justify-between overflow-x-hidden'
        }
      >
        <div
          className={
            'items-center text-darkblue flex gap-1 font-bold px-6 py-4 bg-blue-100 text-[17px]'
          }
        >
          {getChartTitle(t, type, isPreFetching, total)}
        </div>
        <div className={'px-6 py-4'}>
          {!isPreFetching ? (
            <Bar options={getEmailStatisticsChartOptions(showGrid)} data={data} height={430} />
          ) : (
            <div className={'animate-pulse bg-gray-600 h-[430px] rounded-lg text-transparent '}>
              .
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const getChartTitle = (
  t: TLocoType,
  type: EmailStatisticsChartItemProps['type'],
  isPreFetching: boolean,
  total?: number,
) => {
  switch (type) {
    case EmailStatisticsChartAmountEnum.sentAmount: {
      return (
        <>
          <SioTooltip
            wrapperClassName={'truncate'}
            label={t(emailStatisticsChartTitles[type])}
            mode={'overflow'}
          >{`${t(emailStatisticsChartTitles[type])}:`}</SioTooltip>
          {!isPreFetching && total !== undefined ? (
            <span className={'text-blue'}>{total}</span>
          ) : (
            <span className={'animate-pulse bg-gray-600 rounded-lg text-transparent px-5'}>.</span>
          )}
        </>
      )
    }
    case EmailStatisticsChartPercentEnum.spamReportsPercent: {
      return (
        <div className={'flex gap-1.5 items-center w-full'}>
          <SioTooltip
            wrapperClassName={'truncate'}
            label={t(emailStatisticsChartTitles[type])}
            mode={'overflow'}
          >{`${t(emailStatisticsChartTitles[type])}:`}</SioTooltip>
          {!isPreFetching && total !== undefined ? (
            <span className={'text-blue'}>{`${total.toFixed(1)}%`}</span>
          ) : (
            <span className={'animate-pulse bg-gray-600 rounded-lg text-transparent px-5'}>.</span>
          )}
          <Popover
            label={t('dashboard.email_statistics.spam_percent_description')}
            popoverClassName={'max-w-[200px] sm:max-w-[350px]'}
          >
            {show => (
              <QuestionMarkIcon
                className={`${
                  show ? `fill-blue` : 'fill-gray-100'
                } group-focus-visible:fill-blue w-[16px] h-[16px]`}
              />
            )}
          </Popover>
        </div>
      )
    }
    default: {
      return (
        <>
          <SioTooltip
            wrapperClassName={'truncate'}
            label={t(emailStatisticsChartTitles[type])}
            mode={'overflow'}
          >{`${t(emailStatisticsChartTitles[type])}:`}</SioTooltip>
          {!isPreFetching && total !== undefined ? (
            <span className={'text-blue'}>{`${total.toFixed(1)}%`}</span>
          ) : (
            <span className={'animate-pulse bg-gray-600 rounded-lg text-transparent px-5'}>.</span>
          )}
        </>
      )
    }
  }
}

export default EmailStatisticsCharts
