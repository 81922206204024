import { useEmailStatisticsPool } from 'modules/email-statistics/hooks/use-email-statistics-pool'
import {
  MailingPoolStatusColors,
  MailingPoolStatusHelpMessages,
  MailingPoolStatusTitles,
} from 'shared/components/dashboard-mail-editor/enums/mailing-pool-status-enum'
import { getEmailStatusLink } from 'shared/components/dashboard-mail-editor/utils/get-email-status-link'
import { Hint } from 'shared/components/hint'
import TransLoco from 'shared/components/trans-loco'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { dateTimeFormat } from 'shared/utils/date-time-format'

const EmailStatisticsPool = () => {
  const { user } = useUser()
  const { data: poolData } = useEmailStatisticsPool()
  const { t } = useLocoTranslation()
  return (
    <div className={'flex flex-col w-full gap-1.5'}>
      <div className={'flex gap-1 font-bold text-darkblue text-[17px]'}>
        <span>{`${t('dashboard.email_statistics.pool')}:`}</span>
        {poolData ? (
          <Hint
            color={MailingPoolStatusColors[poolData.mailingPool]}
            label={
              <TransLoco
                t={t}
                i18nKey={MailingPoolStatusHelpMessages[poolData.mailingPool]}
                components={{
                  a: (
                    <a
                      href={getEmailStatusLink(user?.dashboardLocale)}
                      target={'_blank'}
                      rel={'noreferrer'}
                      className={'primary-link'}
                    />
                  ),
                }}
              />
            }
          >
            <span>{t(MailingPoolStatusTitles[poolData.mailingPool])}</span>
          </Hint>
        ) : (
          <span className={'animate-pulse bg-gray-600 rounded-lg text-transparent px-5'}>.</span>
        )}
      </div>
      <div className={'flex gap-1 text-darkblue text-[17px]'}>
        <span>{`${t('dashboard.email_statistics.since')}:`}</span>
        {poolData ? (
          poolData.updateDetails ? (
            <Hint
              label={
                <div className={'flex flex-col gap-3'}>
                  <p className={'text-start'}>{`${t('dashboard.email_statistics.from_pool')}: ${t(
                    MailingPoolStatusTitles[poolData.updateDetails.fromPool],
                  )}`}</p>
                  {poolData.updateDetails.fromDate && poolData.updateDetails.toDate && (
                    <p className={'text-start'}>
                      {t('dashboard.email_statistics.update_reason_period', {
                        dateSince: dateTimeFormat(poolData.updateDetails.fromDate, {
                          timeZone: user?.timezone,
                          dateFormat: user?.dateFormat,
                          timeFormat: user?.timeFormat,
                        }),
                        dateTill: dateTimeFormat(poolData.updateDetails.toDate, {
                          timeZone: user?.timezone,
                          dateFormat: user?.dateFormat,
                          timeFormat: user?.timeFormat,
                        }),
                      })}
                    </p>
                  )}
                </div>
              }
            >
              {dateTimeFormat(poolData.mailingPoolUpdatedAt, {
                timeZone: user?.timezone,
                dateFormat: user?.dateFormat,
                timeFormat: user?.timeFormat,
              })}
            </Hint>
          ) : (
            <span>
              {dateTimeFormat(poolData.mailingPoolUpdatedAt, {
                timeZone: user?.timezone,
                dateFormat: user?.dateFormat,
                timeFormat: user?.timeFormat,
              })}
            </span>
          )
        ) : (
          <span className={'animate-pulse bg-gray-600 rounded-lg text-transparent px-5'}>.</span>
        )}
      </div>
    </div>
  )
}

export default EmailStatisticsPool
