import { useEmailStatisticsPool } from 'modules/email-statistics/hooks/use-email-statistics-pool'
import { EmailStatisticsFilterInterface } from 'modules/email-statistics/types/email-statistics-interface'
import React, { FC, useEffect, useMemo } from 'react'
import DateRangePicker, { CustomFilterType } from 'shared/components/date-picker/date-range-picker'
import { DateRangeCustomFilterEnum } from 'shared/components/date-picker/enum/date-range-filter-enum'
import { getLast3MonthsCustomFilter } from 'shared/components/date-picker/utills/set-custom-filter'
import useUser from 'shared/hooks/use-user'
import { setDateWithTimezone } from 'shared/utils/date-time-format'
import { getMonthDateRange, getToday } from 'shared/utils/get-dates'

interface EmailStatisticsFilterProps {
  filter: EmailStatisticsFilterInterface
  setFilter: (filter: EmailStatisticsFilterInterface) => void
}

const EmailStatisticsFilter: FC<EmailStatisticsFilterProps> = ({ filter, setFilter }) => {
  const { user } = useUser()

  const { data: poolData } = useEmailStatisticsPool()

  const customDateFilters: CustomFilterType[] = useMemo(
    () => [getLast3MonthsCustomFilter(user?.timezone)],
    [user?.timezone],
  )

  useEffect(() => {
    if (!poolData) return
    if (poolData.updateDetails?.fromDate && poolData.updateDetails?.toDate) {
      customDateFilters.push({
        slug: DateRangeCustomFilterEnum.evaluation_period,
        handler: () => {
          const dateSince = setDateWithTimezone(poolData.updateDetails?.fromDate, user?.timezone)
          const dateTill = setDateWithTimezone(poolData.updateDetails?.toDate, user?.timezone)
          dateTill.setHours(23, 59, 59, 999)
          return [dateSince, dateTill]
        },
      })
    }
  }, [customDateFilters, poolData, user?.timezone])
  return (
    <DateRangePicker
      disabled={!poolData}
      maxDate={getToday(user?.timezone)}
      date={[filter.dateSince, filter.dateTill]}
      onChange={data => setFilter({ dateSince: data[0], dateTill: data[1] })}
      getDefaultDate={() => getMonthDateRange(user?.timezone)}
      customFilters={customDateFilters}
    />
  )
}

export default EmailStatisticsFilter
