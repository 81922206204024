import { EMAIL_STATISTICS_API } from 'modules/email-statistics/api/email-statistics-api'
import {
  EmailStatisticsChartsInterface,
  EmailStatisticsFilterInterface,
} from 'modules/email-statistics/types/email-statistics-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export const useEmailStatisticsCharts = (filter: EmailStatisticsFilterInterface) => {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    EmailStatisticsChartsInterface
  >({ method: RequestMethodsEnum.get })

  const filterApi = `${filter.dateSince !== null ? `filter[dateSince]=${filter.dateSince}` : ''}${
    filter.dateTill !== null ? `&filter[dateTill]=${filter.dateTill}` : ''
  }`

  return useSWR(() => {
    if (!user || filter.dateSince === null) return
    return `${EMAIL_STATISTICS_API}/chart?${filterApi}`
  }, fetcher)
}
